@use 'variables' as *;

.booleanGroup:focus,
.booleanGroup:focus-within {
  @apply ring ring-green-70;
  .tempo & {
    @apply ring ring-tempo-blue-70;
  }

  input:focus + .checkboxInput,
  input:focus + .radioInput,
  input:focus + .inputButton {
    @apply ring ring-green-70;
    .tempo & {
      @apply ring ring-tempo-blue-70;
    }
  }
}

.checkboxInput,
.radioInput {
  @apply relative flex h-4 min-w-4 items-center justify-center border border-solid border-charcoal-90;

  margin-right: 12px;

  &::before {
    @apply block h-2 w-2 rounded bg-transparent;

    content: '';
    transition:
      background-color 0.2s,
      height 0.2s,
      width 0.2s;
  }

  .checkedInput & {
    @apply border-green-100 bg-green-100 transition-all;
    .tempo & {
      @apply border-tempo-navy-100 bg-tempo-navy-100;
    }

    &::before {
      @apply h-full w-full bg-green-100 transition-all;
      .tempo & {
        @apply bg-tempo-navy-100;
      }
    }
  }

  &:hover {
    @apply border-green-100;
    .tempo & {
      @apply border-tempo-blue-90;
    }
  }

  .disabledInput & {
    @apply cursor-not-allowed border-charcoal-70;
  }
}

.checkboxInput {
  @apply rounded-sm;
}

.radioInput {
  @apply rounded-full;
  padding: #{$spacingXs * 0.5};

  &::before {
    @apply rounded-full;
  }
}

.inputButton {
  &:hover {
    cursor: pointer;
  }
}
