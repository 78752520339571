$radiusCircle: 50%;
$radiusXs: 2px;
$radiusSm: 4px;
$radiusMd: 6px;
$radiusLg: 8px;
$radiusXl: 12px;

$borderWidthSm: 1px;
$borderWidthMd: 2px;
$borderWidthLg: 4px;
$borderWidthXl: 8px;

$spacingXxs: 2px;
$spacingXs: 4px;
$spacingSm: 8px;
$spacingXsmd: 10px;
$spacingSmd: 12px;
$spacingMdSm: 14px;
$spacingMd: 16px;
$spacingLg: 24px;
$spacingXl: 32px;
$spacingXxl: 40px;

$bp1: 550px;
$bp2: 850px;
$bp3: 1100px;
$bp4: 1500px;

$mealCardWidthDesktop: 368px;
$mealCardWidthDesktopRedesign: 300px;
$mealCardWidthMobile: 275px;
$mealCardWidthCompressed: 240px;
$mealCardWidthCompressedRedesign: 200px;
$mealCardPadding: $spacingMd;
$calendarWidth: 250px;
$containerWidth: 1248px;
