// These class names are labeled h1-h7. However, they can be applied
// to ANY of the <h1> - <h6> elements! Make sure to follow
// WAI-ARIA standards for headers: start with h1 and don't skip levels.
// Read more: https://github.com/homechef/mealhand/wiki/Accessibility-Playbook#headings-1

@use 'sass:math';
@use 'variables' as *;

@tailwind base;

@layer base {
  // Home Chef
  .display-text {
    font-family: $leMondeCourrier;
    font-size: math.div(40, $baseFontSize) * 1rem;
    line-height: math.div(48, 40);
    font-weight: 750;

    @media screen and (min-width: $bp1) {
      font-size: math.div(60, $baseFontSize) * 1rem;
      line-height: math.div(68, 60);
    }
  }

  .h1 {
    font-family: $leMondeCourrier;
    font-size: math.div(32, $baseFontSize) * 1rem;
    line-height: math.div(40, 32);
    font-weight: 750;

    @media screen and (min-width: $bp1) {
      font-size: math.div(40, $baseFontSize) * 1rem;
      line-height: (48 * 0.025);
    }
  }

  .h2 {
    font-family: $leMondeCourrier;
    font-size: math.div(24, $baseFontSize) * 1rem;
    line-height: math.div(32, 24);
    font-weight: 750;

    @media screen and (min-width: $bp1) {
      font-size: math.div(32, $baseFontSize) * 1rem;
      line-height: math.div(40, 32);
    }
  }

  .h3 {
    font-family: $karla;
    font-size: math.div(24, $baseFontSize) * 1rem;
    line-height: math.div(32, 24);
    font-weight: 500;

    @media screen and (min-width: $bp1) {
      font-size: math.div(32, $baseFontSize) * 1rem;
      line-height: math.div(40, 32);
    }
  }

  .h4 {
    font-family: $leMondeCourrier;
    font-size: math.div(20, $baseFontSize) * 1rem;
    line-height: (28 * 0.05);
    font-weight: 650;

    @media screen and (min-width: $bp1) {
      font-size: math.div(24, $baseFontSize) * 1rem;
      line-height: math.div(32, 24);
    }
  }

  .h5 {
    font-family: $karla;
    font-size: math.div(20, $baseFontSize) * 1rem;
    line-height: math.div(26, 20);
    font-weight: 500;
  }

  .h6 {
    font-family: $leMondeCourrier;
    font-size: math.div(18, $baseFontSize) * 1rem;
    line-height: math.div(24, 18);
    font-weight: 650;
  }

  .h7 {
    font-family: $karla;
    font-size: math.div(13, $baseFontSize) * 1rem;
    line-height: math.div(16, 13);
    font-weight: 500;
  }

  .text {
    font-family: $karla;
    font-size: math.div(17, $baseFontSize) * 1rem;
    line-height: math.div(24, 17);
    font-weight: 400;
  }

  .textXs {
    font-family: $karla;
    font-size: math.div(13, $baseFontSize) * 1rem;
    line-height: math.div(18, 15);
    font-weight: 400;
  }

  .textSm {
    font-family: $karla;
    font-size: math.div(15, $baseFontSize) * 1rem;
    line-height: math.div(20, 15);
    font-weight: 400;
  }

  .textMd {
    font-family: $karla;
    font-size: math.div(17, $baseFontSize) * 1rem;
    line-height: math.div(20, 17);
    font-weight: 500;
  }

  .text-primary {
    @apply text-green-100;
  }

  .text-primary-dark {
    @apply text-green-120;
  }

  .text-secondary {
    @apply text-charcoal-100;
  }

  .text-negative {
    @apply text-red-100;
  }

  // Tempo

  .tempo {
    .display-text {
      font-family: $dmSerif;
      font-size: math.div(40, $baseFontSize) * 1rem;
      line-height: math.div(48, 40);
      font-weight: 400;

      @media screen and (min-width: $bp1) {
        font-size: math.div(60, $baseFontSize) * 1rem;
        line-height: math.div(68, 60);
      }
    }

    .h1 {
      font-family: $dmSerif;
      font-size: math.div(32, $baseFontSize) * 1rem;
      font-weight: 400;
      line-height: math.div(40, 32);

      @media screen and (min-width: $bp1) {
        font-size: math.div(40, $baseFontSize) * 1rem;
        line-height: (48 * 0.025);
      }
    }

    .h2 {
      font-family: $dmSerif;
      font-weight: 400;
      font-size: math.div(24, $baseFontSize) * 1rem;
      line-height: math.div(32, 24);

      @media screen and (min-width: $bp1) {
        font-size: math.div(32, $baseFontSize) * 1rem;
        line-height: math.div(40, 32);
      }
    }

    .h3 {
      font-family: $aktivGrotesk;
      font-size: math.div(24, $baseFontSize) * 1rem;
      font-weight: 500;
      line-height: math.div(32, 24);

      @media screen and (min-width: $bp1) {
        font-size: math.div(32, $baseFontSize) * 1rem;
        line-height: math.div(40, 32);
      }
    }

    .h4 {
      font-family: $dmSerif;
      font-size: math.div(20, $baseFontSize) * 1rem;
      font-weight: 400;
      line-height: (28 * 0.05);

      @media screen and (min-width: $bp1) {
        font-size: math.div(24, $baseFontSize) * 1rem;
        line-height: math.div(32, 24);
      }
    }

    .h5 {
      font-family: $aktivGrotesk;
      font-size: math.div(20, $baseFontSize) * 1rem;
      font-weight: 500;
      line-height: math.div(26, 20);
    }

    .h6 {
      font-family: $aktivGrotesk;
      font-size: math.div(18, $baseFontSize) * 1rem;
      font-weight: 600;
      line-height: math.div(24, 18);
    }

    .h7 {
      font-family: $aktivGrotesk;
      font-size: math.div(13, $baseFontSize) * 1rem;
      font-weight: 500;
      line-height: math.div(16, 13);
    }

    .text {
      font-family: $aktivGrotesk;
      font-size: math.div(16, $baseFontSize) * 1rem;
      font-weight: 400;
      line-height: math.div(24, 16);
    }

    .textXs {
      font-family: $aktivGrotesk;
      font-size: math.div(13, $baseFontSize) * 1rem;
      font-weight: 400;
      line-height: math.div(18, 15);
    }

    .textSm {
      font-family: $aktivGrotesk;
      font-size: math.div(15, $baseFontSize) * 1rem;
      font-weight: 400;
      line-height: math.div(18, 15);
    }

    .textMd {
      font-family: $aktivGrotesk;
      font-size: math.div(16, $baseFontSize) * 1rem;
      font-weight: 500;
      line-height: math.div(24, 16);
    }

    .text-primary {
      @apply text-tempo-navy-100;
    }

    .text-primary-dark {
      @apply text-tempo-navy-100;
    }

    .text-secondary {
      @apply text-tempo-black-100;
    }

    .text-negative {
      @apply text-red-120;
    }
  }
}
